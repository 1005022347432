import React, { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TableHead from "@mui/material/TableHead"
import TablePaginationActions from "./components/TablePaginationActions"
import Skeleton from "@mui/material/Skeleton"
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import "./style.scss"

// TODO Pass page (pageNumber), limit, sortBy and sortDir as options to DataTable.
// DataTable's job should just be to render the data, not to interact with signals.
const DataTable = ({
  columns,
  data,
  totalPages,
  getTableData,
  loading,
  defaultPage = 0,
  defaultRowsPerPage = 10,
  defaultSortBy = "",
  defaultSortOrder = "asc",
  totalRows = 0,
  isClientSidePagination = false,
  clientSideSort,
  searchTerm,
  pageInfoInitialValue
}) => {
  // Check query params first
  const history = useHistory()
  const { location } = history
  const pageRef = useRef(pageInfoInitialValue ? pageInfoInitialValue : window.history?.state?.pageInfo?? {})
  const [pageNumber, setPageNumber] = useState(pageRef.current.page ? (pageRef.current.page - 1) : defaultPage)
  const [rowsPerPage, setRowsPerPage] = useState(pageRef.current.limit?? defaultRowsPerPage)
  const [sortDir, setSortDir] = useState(pageRef.current.sortDir?? defaultSortOrder)
  const [sortBy, setSortBy] = useState(pageRef.current.sortBy?? defaultSortBy)
  const [tableData, setTableData] = useState([])
  totalRows = totalRows || (totalPages || 0) * rowsPerPage
  totalPages = totalPages || Math.ceil(totalRows / rowsPerPage) || 1

  useEffect(() => {
    setPageNumber(0)
  }, [searchTerm])

  useEffect(() => {
    if (!isClientSidePagination) {
      setTableData(data)
    }
  }, [data, isClientSidePagination])

  useEffect(() => {
    if (isClientSidePagination) {
      const sortedData =
        typeof clientSideSort === "function"
          ? clientSideSort({ data, sortBy: sortBy, sortDir: sortDir })
          : data
      const pageData = sortedData?.slice(
        pageNumber * rowsPerPage,
        pageNumber * rowsPerPage + rowsPerPage
      )
      setTableData(pageData)
    }
  }, [
    isClientSidePagination,
    clientSideSort,
    data,
    pageNumber,
    rowsPerPage,
    sortDir,
    sortBy
  ])

  const setPageInfoFromQueryParams = (pageInfo) => {
    const parsedQueryParams = new URLSearchParams(location?.search)
    setPageNumber(parseInt(parsedQueryParams.get('page')?? 1) - 1)
    setRowsPerPage(parseInt(parsedQueryParams.get('limit')?? 10))
    setSortBy(parsedQueryParams.get('sortBy')?? defaultSortBy)
    setSortDir(parsedQueryParams.get('sortDir')?? defaultSortOrder)
    // setSearchTerm(parsedQueryParams.get('searchTerm'))
    pageInfo.current = {
      page: pageNumber + 1,
      limit: rowsPerPage,
      sortBy: sortBy,
      sortDir: sortDir,
      searchTerm
    }
  }

  useEffect(() => {
    setPageInfoFromQueryParams(pageRef)
  }, [location?.search])

  useEffect(() => {
    if (!isClientSidePagination && typeof getTableData === "function") {
      // Add pageRef as state
      getTableData({
        page: pageNumber + 1,
        limit: rowsPerPage,
        sortBy: sortBy,
        sortDir: sortDir,
        searchTerm
      })
    }
  }, [
    getTableData,
    pageNumber,
    rowsPerPage,
    sortDir,
    sortBy,
    searchTerm,
    isClientSidePagination
  ])

  useEffect(() => {
    !isClientSidePagination && setTableData(data)
  }, [isClientSidePagination, data])

  function updateQueryParams(name, value) {
    const { location } = history
    const currentSearchParams = new URLSearchParams(location.search)
    currentSearchParams.set(name, value)
    // TODO Update history
    // history.replace({ ...location, search: currentSearchParams.toString() })
    // history.push(location.pathname, { ...history.state, dataTablePage: pageNumber })
  }

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage)
    updateQueryParams('page', newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPageNumber(0)
  }

  const handleRequestSort = field => () => {
    const isAsc = sortBy === field && sortDir === "asc"
    setSortDir(isAsc ? "desc" : "asc")
    setSortBy(field)
  }

  const columnsConf = columns.filter(item => !item.hidden)

  return (
    <TableContainer component={Paper} className="data-table-container">
      <Table stickyHeader sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            {columnsConf?.map(column => (
              <TableHeaderCell
                key={`datatable-column-${column.id}`}
                column={column}
                handleRequestSort={handleRequestSort}
                sortDir={sortDir}
                sortBy={sortBy}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              {columnsConf?.map((item, index) => {
                return (
                  <TableCell key={`${item?.id}-${index}`}>
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={10}
                      animation="wave"
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          ) : tableData?.length > 0 ? (
            tableData?.map((row, index) => {
              const rowKey = row.id || `row_${index}`
              return (
                <TableRow hover tabIndex={-1} key={rowKey}>
                  {columnsConf?.map(column => {
                    const value = row[column.id]
                    return (
                      <TableCell
                        key={`${rowKey}_${column.id}`}
                        align={column.align}
                      >
                        {typeof column.renderColumn === "function"
                          ? column.renderColumn(row)
                          : value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })
          ) : (
            <TableRow>
              <TableCell colSpan={columnsConf?.length} align="center">
                No data available to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}
              colSpan={columnsConf?.length}
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={pageNumber}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelDisplayedRows={({ page }) => {
                return `Page ${page + 1} of ${totalPages}`
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

const TableHeaderCell = props => {
  const { column, handleRequestSort, sortDir, sortBy } = props

  return (
    <TableCell
        align={column.align}
        style={{ width: column.width }}
      >
        {column?.sortable ? (
          <div
            className={`${column?.sortable ? "sortable-cell" : ""}`}
            onClick={handleRequestSort(column?.id)}
          >
            {typeof column?.renderHeader === "function"
              ? column?.renderHeader()
              : column.label}
            <div className="sort-icons">
              <ArrowDropUpIcon
                className={`sort-asc ${
                  sortDir === "asc" && sortBy === column.id
                    ? "active"
                    : ""
                }`}
              />
              <ArrowDropDownIcon
                className={`sort-desc ${
                  sortDir === "desc" && sortBy === column.id
                    ? "active"
                    : ""
                }`}
              />
            </div>
          </div>
        ) : (
          <div>
            {typeof column?.renderHeader === "function"
              ? column?.renderHeader()
              : column.label}
          </div>
        )}
      </TableCell>
  )
}

export default DataTable
